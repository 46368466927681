<style lang="less" scoped>
.extra-wrapper {
  line-height: 55px;
  padding-right: 24px;

  .extra-item {
    display: inline-block;
    margin-right: 24px;

    a {
      margin-left: 24px;
    }
  }
}

.antd-pro-pages-dashboard-analysis-twoColLayout {
  position: relative;
  display: flex;
  display: block;
  flex-flow: row wrap;
  padding-bottom: 50px;
}

.antd-pro-pages-dashboard-analysis-salesCard {
  height: calc(100% - 24px);
  /deep/ .ant-card-head {
    position: relative;
  }
}

.dashboard-analysis-iconGroup {
  i {
    margin-left: 16px;
    color: rgba(0, 0, 0, 0.45);
    cursor: pointer;
    transition: color 0.32s;
    color: black;
  }
}
.analysis-salesTypeRadio {
  position: absolute;
  right: 54px;
  bottom: 12px;
}

.table-operator {
  margin-top: 20px;
}
</style>

<style lang="less" scoped>
/deep/ .ant-card-body {
  padding-bottom: 14px !important;
}
.ant-input-affix-wrapper,
.ant-select-selection--single {
  height: 35px !important;
}
.ant-select-selection__rendered {
  line-height: 35px !important;
}
.input-left {
  width: 100px !important;
}

.ant-input {
  height: 35px !important;
}
/deep/ .ant-form-item-with-help {
  margin-bottom: 0 !important;
  line-height: 25px;
}
/deep/ .ant-upload-select-picture-card {
  min-width: 80px !important;
  min-height: 80px !important;
}
.ant-card-head {
  background-color: #2c3e5023;
}
/deep/ .ant-card {
  height: 100% !important;
}
</style>
<template>
  <div class="page-header-index-wide" v-if="isRouterAlive">
    <div class="antd-pro-pages-dashboard-analysis-twoColLayout" :class="isDesktop() ? 'desktop' : ''">
      <div class="table-operator" style="margin-top: 20px">
        <a-button type="primary" @click="validate()" v-preventReClick>下单并接单</a-button>
      </div>
      <a-row :gutter="24" type="flex" :style="{ marginTop: '24px' }">
        <a-col :xl="12" :lg="24" :md="24" :sm="24" :xs="24">
          <a-card class="card" title="寄方" :bordered="false">
            <send-form ref="send" :showSubmit="false" :SendTheIndex="'1'" />
          </a-card>
        </a-col>

        <a-col :xl="12" :lg="24" :md="24" :sm="24" :xs="24">
          <a-card class="card" title="收方" :bordered="false">
            <recipient-form ref="recipient" :showSubmit="false" :debit="'1'" />
          </a-card>
        </a-col>
      </a-row>

      <a-row :gutter="24" type="flex" :style="{ marginTop: '24px' }">
        <a-col :xl="8" :lg="24" :md="24" :sm="24" :xs="24">
          <a-card class="card" title="商品信息" :bordered="false">
            <goods-form ref="goods" :showSubmit="false" />
          </a-card>
        </a-col>

        <a-col :xl="16" :lg="24" :md="24" :sm="24" :xs="24">
          <a-card :bordered="false" title="订单信息" :style="{ height: '100%' }">
            <order-form ref="order" :showSubmit="false" />
          </a-card>
        </a-col>
      </a-row>
    </div>
       <a-modal v-model="orderConfirmVisible" title="下单成功">
        <p>下单成功，请提醒调度及时安排提货</p>
        <template slot="footer">
          <a-button key="forward1"  @click="orderFreshen">刷新页面</a-button>
          <a-button key="forward"  type="primary" @click="orderConfirm">确认</a-button>
        </template>
      </a-modal>

  </div>
</template>

<script>
import moment from 'moment'
import { mixinDevice } from '@/utils/mixin'
import SendForm from './OnlineDisperseOrder/SendForm'
import RecipientForm from './OnlineDisperseOrder/RecipientForm'
import GoodsForm from './WholeCarOrder/GoodsForm'
import OrderForm from './WholeCarOrder/OrderForm'
import { enumerationData  } from '@/api/common'

import {
  onlineDisperseOrder,
  saveOrder,
  orderTransportPrice,
  saveMultiOrder,
  requestShipperAddressParam,
  requestReceiverAddressParam,
} from '@/api/order'

export default {
  name: 'WholeCarOrder',
  mixins: [mixinDevice],
  components: {
    SendForm,
    RecipientForm,
    GoodsForm,
    OrderForm,
    onlineDisperseOrder,
  },

  data() {
    return {
      isRouterAlive: true,
      orderConfirmVisible:false,
      successMsg: '',
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    loadData() { 
     
      this.$refs.send.send()
      this.$refs.recipient.recipient()      
      this.$refs.goods.goods()
      this.$refs.order.order()
      this.loadEnumerationData()
    },

    loadEnumerationData(){
        var enumerationParam = {tableName:'sysCOrder'}
        enumerationData(enumerationParam)
         .then(res => {
            if(res.code == 0){

              this.$refs.order.orderLoad(res.result)

            }else{
              this.$notification.error({
                message: '订单加载失败',
                description: res.message
              })
            }
          })


        var enumerationParam = {tableName:'sysCOrderGoods'}
        enumerationData(enumerationParam)
         .then(res => {
            if(res.code == 0){

              this.$refs.order.goodsLoad(res.result)

            }else{
              this.$notification.error({
                message: '订单加载失败',
                description: res.message
              })
            }
          })

     },

    validate() {
      const {
        $refs: { send, recipient, goods, order },
        $notification,
      } = this
      const sendForm = new Promise((resolve, reject) => {
        send.form.validateFields((err, values) => {
          if (err) {
            reject(err)
            return
          }
          var valuesData = requestShipperAddressParam(values)
          resolve(valuesData)
        })
      })

      const recipientForm = new Promise((resolve, reject) => {
        recipient.form.validateFields((err, values) => {
          if (err) {
            reject(err)
            return
          }
          var valuesData = requestReceiverAddressParam(values)
          resolve(valuesData)
        })
      })

      const goodsForm = new Promise((resolve, reject) => {
        goods.form.validateFields((err, values) => {
          if (err) {
            reject(err)
            return
          }
          values.expectPickupDate = ''
          values.expectDispatchDate = ''
          resolve(values)
        })
      })

      const orderForm = new Promise((resolve, reject) => {
        order.form.validateFields((err, values) => {
          values.expectPickupDate = moment(values.expectPickupDate).format('YYYY-MM-DD HH:mm:ss')
          values.expectDispatchDate = moment(values.expectDispatchDate).format('YYYY-MM-DD HH:mm:ss')
          if (err) {
            reject(err)
            return
          }
          resolve(values)
        })
      })

      // clean this.errors
      this.errors = []
      const params = {}
      Promise.all([sendForm, recipientForm, goodsForm, orderForm])
        .then((list_values) => {
          var sendInfo = list_values[0]
          var goodsInfo = list_values[2]
          sendInfo.goodsInfo = goodsInfo
          var shipperList = [sendInfo]
          var recipientInfo = list_values[1]
          var receiverList = [recipientInfo]
          var orderParam = { pickupWay: 1, pickupSeq: 1, dispatchWay: 1, dispatchSeq: 1 }
          orderParam = Object.assign({}, orderParam, list_values[3])
          orderParam.shipperList = shipperList
          orderParam.receiverList = receiverList
          console.log(orderParam)

          return saveMultiOrder(orderParam).then((res) => {
            if (res.code == 0) {
              const _this = this
              this.orderConfirmVisible = true              
            } else {
              this.$notification['error']({
                message: '下单失败',
                description: res.message,
              })
            }
            return res.result
          })
        })
        .catch(() => {})
    },

    orderFreshen(){
        this.orderConfirmVisible = false
        this.loadData()        
    },
    orderConfirm(){
        this.orderConfirmVisible = false
    },
  },

  created() {},
}
</script>

